import React from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faSquareYoutube,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { MdLocationOn } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Map() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row mx-md-5 mx-3">
          <div className="col-md-6 col-sm-12">
            <div className="title_section">
              <p
                className="poppins-700"
                style={{
                  fontSize: "50px",
                  lineHeight: "56px",
                  marginBottom: "12px",
                  fontWeight: 500,
                }}
              >
                Get Your{" "}
                <span style={{ fontWeight: 700, color: "#03A9F4" }}>
                  Confidential Consultation
                </span>
              </p>
              <p
                className="poppins-500 text-dark"
                style={{
                  fontSize: "21px",
                  lineHeight: "25.6px",
                  fontWeight: 400,
                }}
              >
                We have world-class, flexible support via live chat, email, and
                phone. We guarantee that you’ll be able to have any issue
                resolved within 24 hours. You can contact our support
                immediately here.
              </p>
            </div>

            {/* Address Section */}
            <div
              className="address mt-3"
              style={{
                background: "#f9f9f9",
                padding: "20px",
                width: "380px",
                borderRadius: "10px",
              }}
            >
              <p
                className="poppins-700"
                style={{
                  fontSize: "40px",
                  lineHeight: "60px",
                  marginBottom: "12px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                <MdLocationOn size={50} /> Address
              </p>
              <p className="poppins-500 text-dark" style={{ fontSize: "16px" }}>
                455 Wallstreet St., New York, VA 12364, USA
              </p>
            </div>

            {/* Phone Section */}
            <div
              className="phone mt-3"
              style={{
                background: "#f9f9f9",
                padding: "20px",
                width: "380px",
                borderRadius: "10px",
              }}
            >
              <p
                className="poppins-700"
                style={{
                  fontSize: "40px",
                  lineHeight: "60px",
                  marginBottom: "12px",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaPhoneVolume size={40} /> Phones
              </p>
              <p className="poppins-500" style={{ fontSize: "16px" }}>
                +1 123 456 78910
              </p>
              <p className="poppins-500" style={{ fontSize: "16px" }}>
                +1 123 456 78910
              </p>
            </div>

            {/* Email Section */}
            <div
              className="e-mail mt-3"
              style={{
                background: "#f9f9f9",
                padding: "20px",
                width: "380px",
                borderRadius: "10px",
              }}
            >
              <p
                className="poppins-700"
                style={{
                  fontSize: "40px",
                  lineHeight: "60px",
                  marginBottom: "12px",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdEmail size={40} /> E-mail
              </p>
              <p className="poppins-500" style={{ fontSize: "16px" }}>
                Patsy@brownielinkservices.com
              </p>
            </div>

            <div className="icons d-flex mt-5">
              <div className="px-2">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ fontSize: "22px" }}
                />
              </div>
              <div className="px-2">
                <FontAwesomeIcon
                  icon={faTwitterSquare}
                  style={{ fontSize: "22px" }}
                />
              </div>
              <div className="px-2">
                <FontAwesomeIcon
                  icon={faSquareYoutube}
                  style={{ fontSize: "22px" }}
                />
              </div>
              <div className="px-2">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ fontSize: "22px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12 d-flex justify-content-end">
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={59.955413}
                  lng={30.337844}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>

            <style jsx>{`
              .map-container {
                width: 100%;
              }

              @media (max-width: 768px) {
                .map-container {
                  height: 400px;
                  width: 100%;
                }
              }
            `}</style>
          </div>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 768px) {
          .title_section p {
            font-size: 25px !important;
            line-height: 30px !important;
          }

          .address,
          .phone,
          .e-mail {
            width: 100% !important;
            padding: 15px !important;
          }

          .address p,
          .phone p,
          .e-mail p {
            font-size: 20px !important;
            line-height: 40px !important;
          }

          .icons .px-2 {
            padding: 0 10px;
          }

          .icons .px-2 svg {
            font-size: 30px !important;
          }

          .address p,
          .phone p,
          .e-mail p {
            font-size: 16px !important;
          }
        }
      `}</style>
    </>
  );
}
