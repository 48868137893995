import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteChatApi, chatListApi } from "../../_redux/features/messageSlice";
import { io } from "socket.io-client";
import { chatURL } from "../../_redux/axios/axios";

var socket;

export default function LogOut(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ENDPOINT = chatURL;
  const userData = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", userData);
    socket.on("connection", () => {});
  }, []);

  const user = userData.user;

  const getData = async () => {
    await dispatch(chatListApi({ id: userData?.user?._id })).then(
      (response) => {}
    );
  };
  const DeleteSuccessCallback = () => {
    props.closeWindow();
    props.setscreenCount("welcome");
    props.setConversation("");
    getData();
  };
  const DeleteChat = () => {
    dispatch(
      deleteChatApi({ chatId: props.chatId, onSuccess: DeleteSuccessCallback })
    );
  };
  return (
    <>
      <div className="card border-0 p-2 px-sm-0 px-0">
        <div className="card-body px-md-5 p-sm-0 p-0">
          <div>
            <p className="poppins-600 fw text-center fs-md-5">
              Are You Sure to delete chat ?
            </p>

            <div className="d-flex justify-content-center md-5">
              <div className="mx-3">
                <button
                  type="button"
                  className="btn btn-light border fw-bold fs-md-5 btn-cus"
                  onClick={props.closeWindow}
                >
                  Cancel
                </button>
              </div>
              <div className="mx-3">
                <button
                  className="btn btn-dark border  fw-bold fs-md-5 btn-cus"
                  onClick={DeleteChat}
                >
                  Delete!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
