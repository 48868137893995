import React from "react";
import law1 from "../../assets/Law-assets/law1.png";
import law2 from "../../assets/Law-assets/law2.png";
import { Typography } from "@mui/material";

const Section5 = () => {
  return (
    <>
      <div className="container mt-5">
        <Typography
          className="mt-4 mx-md-5 mx-3"
          variant="h2"
          sx={{
            fontSize: {
              xs: "24px",
              sm: "30px",
              lg: "40px",
            },
            fontWeight: "600",
            fontFamily: "poppins,sans-serif",
          }}
        >
          About Brownie Link Services
        </Typography>

        <p
          className="poppins-500 mb-4 text-dark mt-4 mx-md-5 mx-3"
          style={{
            textAlign: "left",
            fontSize: "16px",
            lineHeight: "25.6px",
          }}
        >
          Hello, my name is Patsy Moy. I am the founder of Brownie Link
          Services. I have practiced law full-time since 2015 and will fully
          dedicate myself to managing this legal market e-platform in 2024. One
          of the most rewarding tasks during my legal practice is to provide
          legal advice to the public under a free legal advice program run by
          the Home Affairs Department in Hong Kong. The program has offered an
          opportunity to meet up with people from all walks of life. People come
          to me with a wide range of legal questions, for matters as trivial as
          minor traffic offences and legal lawsuits arising from water leakage
          in their buildings to some of the most important decisions in their
          life, such as divorce, child custody, probate or even criminal
          matters. Even for legal questions which may look unimportant, such as
          minor traffic offences, they could impact the livelihood of a family
          when the offender is a bus driver who may lose his job after
          conviction Even the slightest legal trouble can create enormous mental
          stress on any law-abiding person. By and large, all people want are
          both a quick fix and a complete cure to the legal headaches.
        </p>
      </div>

      <div className="container mt-5">
        <div className="row mt-4 d-flex mx-md-5 mx-3">
          <div className="col-md-5">
            <img
              src={law1}
              alt="law image 1"
              className="img-fluid"
              style={{ objectFit: "cover", width: "100%" }}
            />
          </div>

          <div className="col-md-7 mt-md-0 mt-5">
            <p
              className="poppins-500 mb-4 text-dark"
              style={{
                textAlign: "left",
                fontSize: "16px",
                lineHeight: "25.6px",
              }}
            >
              Even the slightest legal trouble can create enormous mental stress
              on any law-abiding person. By and large, all people want are both
              a quick fix and a complete cure to the legal headaches. But the
              free legal advice program only offers each citizen about 20
              minutes for preliminary legal advice. Those who need further
              advice always ask me to recommend a law firm, or sometimes to
              estimate the legal cost if they want to hire a lawyer.
              Unfortunately, I am not allowed under the law or the Code of
              Practice to make any referral. What I could do to help is suggest
              them to visit the Law Society for a list of law firms and lawyers
              for their own selection. Only offers each citizen about 20 minutes
              for preliminary legal advice. Those who need further advice always
              ask me to recommend a law firm, or sometimes to estimate the legal
              cost if they want to hire a lawyer.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex align-items-start mx-md-5 mx-3">
          <div className="col-md-5">
            <Typography
              className="mt-4"
              variant="h2"
              sx={{
                fontSize: {
                  xs: "24px",
                  sm: "30px",
                  lg: "40px",
                },
                fontWeight: "600",
                fontFamily: "poppins,sans-serif",
              }}
            >
              Why Choose Us?
            </Typography>
          </div>

          <div className="col-md-7 mt-4">
            <p
              className="poppins-500 mb-4 text-dark"
              style={{
                textAlign: "left",
                fontSize: "16px",
                lineHeight: "25.6px",
              }}
            >
              For the legal industry, many lawyers in Hong Kong as well as in
              other Asian cities still remain very conservative or even passive
              when it comes to marketing, which is very likely due to the
              culture of maintaining their professional image. Further, even
              online tools and apps have become a global trend for many years,
              many law firms still hesitate to use the new technology for
              different reasons and have yet prepared to build their own
              business websites for brand promotion. However, many business
              opportunities may slip away due to their lack of readiness to
              fully embrace the technology. Therefore, there is the market niche
              to build an e-platform to bridge lawyers with lay people who are
              anxiously looking for lawyers. Since North America including
              Canada is well-known for its global leadership in technology,
              Brownie Link incorporates the technology from Canada into the
              legal sector in Hong Kong as its first step in hopes of building a
              win-win business environment for both Canada and the whole Asia
              eventually. This e-platform is designed to provide people a 7x24
              channel to select and access lawyers at their fingertips while we
              are also offering lawyers three different levels of membership to
              meet their budget and marketing needs. We’d hope that this
              innovative mode of marketing will not just bring a stimulus to the
              legal industry in Asia, but that it will also benefit the general
              public by providing them with an informed choice while also paving
              the path for the IT sector in Canada to enter the Asian legal
              market.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex align-items-start mx-md-5 mx-3">
          <div className="col-md-5">
            <Typography
              className="mt-4"
              variant="h2"
              sx={{
                fontSize: {
                  xs: "24px",
                  sm: "30px",
                  lg: "40px",
                },
                fontWeight: "600",
                fontFamily: "poppins,sans-serif",
              }}
            >
              Why did I name my company Brownie Link?
            </Typography>
          </div>

          <div className="col-md-7">
            <p
              className="poppins-500 mb-5 text-dark mt-md-4"
              style={{
                textAlign: "left",
                fontSize: "16px",
                lineHeight: "25.6px",
              }}
            >
              It is never an easy task to decide a company name that can
              perfectly match its business nature. On its surface, the name
              Brownie Link has no connection with legal services. But what about
              Apple, Oracle and Amazon? These company names are always entirely
              unrelated to their businesses. According to Walter Isaacson's
              biography of Steve Jobs, Jobs came up with the name simply because
              he liked apples. For me, I like the Brownie dessert. To some
              degree, my choice of the company name is out of my personal
              preference. Besides, Brownies are also another name for Girl
              Scouts. Most important, the motto of Brownies is “Lend a Hand” -
              which is also the mission behind Brownie Link Services and its
              promise to the users when they are in need of our services.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section5;
