import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import bars from "../../../assets/images/Bars.png";
import arrow from "../../../assets/images/diagonal-arrows 1.png";
import {
  OurMissionWrapper,
  ImageWrapper,
  MissionText,
  NumberAndDetailsWrapper,
  NumberTitleText,
  OurMissionDetailsWrapper,
} from "./OurMission.style";

const OurMission = () => {
  return (
    <OurMissionWrapper>
      <Grid container columnSpacing={{ xs: "30px", lg: "90px" }}>
        <Grid item xs={12} lg={6} md={6} sm={12}>
          <MissionText>Our Mission</MissionText>
          <ImageWrapper>
            <img src={bars} alt="Bars" />
          </ImageWrapper>
        </Grid>

        <Grid item xs={12} lg={5} md={5} sm={12}>
          <Box className="row" marginBottom={4} marginTop={4}>
            <OurMissionDetailsWrapper>
              <NumberAndDetailsWrapper>
                <h2>25</h2>
                <NumberTitleText>Years Experience</NumberTitleText>
              </NumberAndDetailsWrapper>

              <NumberAndDetailsWrapper>
                <img src={arrow} alt="Arrow" />
              </NumberAndDetailsWrapper>
            </OurMissionDetailsWrapper>
          </Box>

          <Box className="row">
            <OurMissionDetailsWrapper>
              <NumberAndDetailsWrapper>
                <h2>1M</h2>
                <NumberTitleText>Clients Satisfaction</NumberTitleText>
              </NumberAndDetailsWrapper>
              <Box>
                <NumberAndDetailsWrapper>
                  <h2>10K</h2>
                  <NumberTitleText>Verified Lawyers</NumberTitleText>
                </NumberAndDetailsWrapper>
              </Box>
            </OurMissionDetailsWrapper>
          </Box>

          <Box sx={{ marginTop: { xs: "10px", lg: "50px" } }}>
            <Typography className="mission-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
            <Typography className="mission-interest">
              Interesting to hire us?
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </OurMissionWrapper>
  );
};

export default OurMission;
