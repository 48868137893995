import styled from "styled-components";
import { Box } from "@mui/material";

// Background image import, assuming it's provided
import bgImage from "../../../assets/images/bg-image.png";

// Define mobile-first styles using styled-components
export const HeroSectionWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${bgImage})`,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  position: "relative",
  marginTop: "10px",

  ".hero-first-text": {
    textAlign: "center",
    marginBottom: "20px",
  },

  ".first-text-main": {
    fontWeight: 300,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000",

    // Responsive styling for smaller screens
    "@media (max-width: 600px)": {
      fontSize: "18px",
      lineHeight: "26px",
    },
  },

  ".hero-button": {
    marginTop: "15px",
    padding: "10px 20px",
    fontSize: "14px",
    backgroundColor: "#03A9F4",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",

    "&:hover": {
      backgroundColor: "#0288D1",
    },

    "@media (max-width: 600px)": {
      fontSize: "12px",
      padding: "8px 16px",
    },
  },

  ".girl-image": {
    width: "100%",
    textAlign: "center",
    // marginTop: "20px",

    img: {
      width: "100%",
      maxWidth: "300px",
      height: "auto",
      objectFit: "cover",
    },
  },

  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",

    ".girl-image": {
      width: "100%",
      justifyContent: "center",

      paddingLeft: 0,
    },
  },
}));
