import React from "react";
import { HeroSectionWrapper } from "./HomeMobile.style";
import main from "../../../assets/images/Rectangle 14.png";

const HeroSectionMobile = () => {
  const handleClick = () => {
    // Handle click logic here
  };

  return (
    <HeroSectionWrapper>
      <div className="flex flex-col-reverse">
        <div className="hero-first-text">
          <p className="first-text-main">
            Navigate{" "}
            <span style={{ color: "#03A9F4", fontWeight: 400 }}>Legal</span>
            <br />
            <span style={{ color: "#03A9F4", fontWeight: 400 }}>
              Challenges
            </span>
            with Trust
          </p>
          <button onClick={handleClick} className="hero-button">
            Get a Consult
          </button>
        </div>
        <div className="">
          <img src={main} alt="Lawyer" />
        </div>
      </div>
    </HeroSectionWrapper>
  );
};

export default HeroSectionMobile;
