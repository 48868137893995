import styled from "styled-components";
import { Box, Typography } from "@mui/material";

// Main wrapper for the mobile layout
export const OurMissionWrapper = styled(Box)`
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    padding: 40px;
  }
`;

// Styling for the "Our Mission" title
export const MissionText = styled(Typography)`
  font-size: 24px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
  text-align: center !important;

  @media (min-width: 600px) {
    font-size: 36px !important;
  }

  @media (min-width: 900px) {
    font-size: 50px !important;
  }
`;

// Image wrapper for the mobile image styling
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  @media (min-width: 600px) {
    margin-top: 40px;

    img {
      max-width: 500px;
    }
  }
`;

// Wrapper for the number and details section
export const NumberAndDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 40px;
    font-weight: 500;
    margin: 0;
  }

  @media (min-width: 600px) {
    h2 {
      font-size: 60px;
    }
  }

  @media (min-width: 900px) {
    h2 {
      font-size: 80px;
    }
  }
`;

// Text for the number title
export const NumberTitleText = styled(Typography)`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;

  @media (min-width: 600px) {
    font-size: 18px;
  }
`;

// Wrapper for the mission details
export const OurMissionDetailsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;

  img {
    height: 80px;
  }

  @media (min-width: 600px) {
    img {
      height: 100px;
    }
  }

  @media (min-width: 900px) {
    img {
      height: 130px;
    }
  }
`;
