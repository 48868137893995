import React from "react";
import image1 from "../../../assets/brownieee assets/img1.jpg";
import FirmDetailsTabs from "./FirmDetailsTabs/FirmDetailsTabs";
import { useLocation } from "react-router-dom";

const FirmsDetails = () => {
  const location = useLocation();
  const { cardInfo, firmData, outsiderFirmId } = location.state;
  return (
    <>
      <br />
      <div className="background-rectangle bg w-[100%] flex flex-row container items-center mobile-class">
        <div className=" w-1000 padlig">
          <h1
            style={{
              fontWeight: 400,
              fontFamily: "poppins",
              // fontSize: "56px",
              lineHeight: "76px",
              marginBottom: "13px",
            }}
            className="cardHeading"
          >
            <span
              style={{
                fontWeight: 700,
              }}
            >
              Criminal
            </span>
            Defense
          </h1>
          <p
            style={{
              fontWeight: 400,
              fontFamily: "poppins",
              // fontSize: "",
              lineHeight: "36px",
            }}
            className="cardPara"
          >
            These firms typically offer a range of services, from basic legal
            advice to full representation, often at reduced rates or on a
            sliding scale based on income. By making legal services more
            accessible, these firms play a vital role in ensuring that justice
            is served.
          </p>
        </div>

        <div className="w-1000">
          <img
            src={cardInfo?.image?.length > 0 ? cardInfo?.image : image1}
            alt=""
            style={{
              // maxHeight: "70vh",
              width: "100%",
              objectFit: "cover",
              objectPosition: "top",
            }}
            className="img-fluid rounded-5 w-[600px] imgHeight"
          />
        </div>
      </div>
      <div className="conatiner">
        <h1 className="p-5 fs-1 fw-bold pb-4" style={{ fontFamily: "poppins" }}>
          {cardInfo?.name}
        </h1>
        <FirmDetailsTabs firmData={firmData} outsiderFirmId={outsiderFirmId} />
      </div>
    </>
  );
};

export default FirmsDetails;
