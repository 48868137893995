import { Box, Container, Grid, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import React from "react";
import group from "../../assets/images/team 1.svg";
import question from "../../assets/images/question 1.svg";
import help from "../../assets/images/help 1.svg";
import { AboutSectionWrapper } from "./Home.style";

const AboutSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div
      className={`container ml-0`}
      style={{
        // paddingTop: "-200px",
        position: "relative",
        marginTop: isMobile ? "20px" : "-7.4rem",
        zIndex: 10,
      }}
    >
      <AboutSectionWrapper className="mx-md-5  mx-3 ml-0">
        <Box
          sx={{ background: "#E7E9FA", borderRadius: "15px", padding: "35px" }}
        >
          <img
            src={group}
            alt=""
            style={{ width: "83.32px", hegiht: "84.57px", marginBottom: "7px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "36px" },
              fontWeight: "500",
              marginBottom: "10px",
              fontFamily: "poppins",
            }}
          >
            Who are we
          </Typography>
          <Typography
            style={{
              fontFamily: "poppins",
              fontSize: "18px",
              color: "#63666A",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            background: "#EAFFED",
            borderRadius: "15px",
            padding: "35px",
            fontFamily: "poppins",
          }}
        >
          <img
            src={question}
            alt=""
            style={{ width: "90px", hegiht: "89px" }}
          />
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "36px" },
              fontWeight: "500",
              marginBottom: "10px",
              fontFamily: "poppins",
            }}
          >
            What we do
          </Typography>
          <Typography
            style={{
              fontFamily: "poppins",
              fontSize: "18px",
              color: "#63666A",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
          </Typography>
        </Box>
        <Box
          sx={{ background: "#EDF7FF", borderRadius: "15px", padding: "35px" }}
        >
          <img src={help} alt="" style={{ width: "90px", hegiht: "89px" }} />
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "36px" },
              fontWeight: "500",
              marginBottom: "10px",
              fontFamily: "poppins",
            }}
          >
            Help you
          </Typography>
          <Typography
            style={{
              fontFamily: "poppins",
              fontSize: "18px",
              color: "#63666A",
              fontWeight: 400,
              lineHeight: "24px",
              // wordSpacing: "2px",
              // letterSpacing: "-1px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
          </Typography>
        </Box>
      </AboutSectionWrapper>
    </div>
  );
};

export default AboutSection;
