import React, { useEffect } from "react";
import Map from "./Map";
import ContactForm from "./ContactForm";
import main from "../../assets/contact-assets/main.png";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="mb-5">
        <Map />
        <ContactForm />
      </div>
    </>
  );
}
