import React from "react";

function MessageSelf({ props }) {
  const timestamp = props?.createdAt;
  const date = new Date(timestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return (
    <div className="self-message-container">
      <div className="messageBox">
        <p style={{ color: "black" }}>{props.content}</p>
        <p className="self-timeStamp" style={{ color: "black" }}>
          {hours}:{minutes}
        </p>
      </div>
      <div className={"conversation-container"}>
        {/* <p className={"con-icon"}>{props?.sender?.name[0]}</p> */}
        <img
          className={"con-icon"}
          src="https://ctmirror-images.s3.amazonaws.com/wp-content/uploads/2021/01/dummy-man-570x570-1.png"
          alt="dummy"
        ></img>
      </div>
    </div>
  );
}

export default MessageSelf;
